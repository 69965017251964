<template>
  <div>
    <b-row class="mb-3">
      <b-col cols="12" lg="3">
        <div class="form-group mb-0">
          <label for="search">Search:</label>
          <input
            id="search"
            type="text"
            class="form-control"
            placeholder="Search: Name"
            v-model="filter.search"
            @change="changeSearch()"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group mb-0">
          <label for="search">Order:</label>
          <v-select
            id="group"
            label="name"
            v-model="filter.order"
            :options="orderList"
            placeholder="-- Pilih --"
            :reduce="(orderList) => orderList.value"
            @input="changeType()"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          />
        </div>
      </b-col>
    </b-row>
    <form @submit.prevent="saveWinners">
      <b-row>
        <b-col cols="12" lg="6">
          <b-card
            v-for="(candidate, $index) in winnerData"
            :key="candidate.uuid"
            class="position-relative"
          >
            <div class="form-group">
              <div class="d-flex align-items-center justify-content-between">
                <div class="custom-control custom-checkbox">
                  <input
                    name="childCheckbox"
                    type="checkbox"
                    :id="'competition' + candidate.uuid"
                    :value="candidate.uuid"
                    v-model="selectedItems"
                    class="custom-control-input checkbox"
                  />
                  <label
                    class="custom-control-label text-capitalize"
                    :for="'competition' + candidate.uuid"
                  >
                    Kandidat {{ $index + 1 }}
                  </label>
                </div>
                <div @click="deleteItem(candidate.uuid)">
                  <feather-icon icon="TrashIcon" />
                </div>
              </div>
            </div>
            <template v-if="candidate.content_file.file != null">
              <img
                v-if="isImage(candidate.content_file.file)"
                :src="candidate.content_file.file"
                class="media_size mb-2"
                alt=""
              />
              <video v-else controls class="media_size mb-2">
                <source :src="candidate.content_file.file" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </template>
            <template v-else>
              <iframe
                height="292"
                class="w-100 mb-2"
                :src="
                  isUrl(candidate.content_file.youtube_id)
                    ? 'https://www.youtube.com/embed/' +
                      extractVideoId(candidate.content_file.youtube_id)
                    : 'https://www.youtube.com/embed/' +
                      candidate.content_file.youtube_id
                "
              ></iframe>
            </template>
            <div class="d-flex justify-content-between">
              <p class="mb-0">
                {{ candidate.owner ? candidate.owner.name : "-" }}
              </p>
              <div class="d-flex align-items-center mb-2" style="gap: 10px">
                <p class="mb-0">{{ candidate.like_count }}</p>
                <p class="mb-0">Likes</p>
              </div>
            </div>
            <p class="mb-0">{{ candidate.caption }}</p>
          </b-card>
          <infinite-loading :identifier="infiniteId" @infinite="getData">
            <div slot="spinner">Loading...</div>
            <div slot="no-more">No more Candidate</div>
            <div slot="no-results">No results Candidate</div>
          </infinite-loading>
        </b-col>
        <b-col cols="12" lg="6" class="position-relative">
          <b-card title="Juara List" class="sticky-top">
            <ul>
              <li
                v-for="(itemId, index) in selectedItems"
                :key="index"
                class="d-flex justify-content-between mb-2"
              >
                <div class="d-flex" style="gap: 10px">
                  <template v-if="getItemProperty(itemId, 'content_file.file')">
                    <img
                      v-if="
                        isImage(getItemProperty(itemId, 'content_file.file'))
                      "
                      :src="getItemProperty(itemId, 'content_file.file')"
                      class="media-list-size"
                    />
                    <video v-else controls class="media-list-size">
                      <source
                        :src="getItemProperty(itemId, 'content_file.file')"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </template>
                  <template v-else>
                    <iframe
                      class="media-list-size"
                      :src="
                        isUrl(
                          getItemProperty(itemId, 'content_file.youtube_id')
                        )
                          ? 'https://www.youtube.com/embed/' +
                            extractVideoId(
                              getItemProperty(itemId, 'content_file.youtube_id')
                            )
                          : 'https://www.youtube.com/embed/' +
                            getItemProperty(itemId, 'content_file.youtube_id')
                      "
                    ></iframe>
                  </template>
                  <div>
                    <h4>{{ getItemProperty(itemId, "owner.name") || "-" }}</h4>
                    <p class="text-elipsis">
                      {{ getItemProperty(itemId, "caption") }}
                    </p>
                  </div>
                </div>
                <div class="d-flex flex-column justify-content-between">
                  <div class="form-group">
                    <label :for="'setWinner_' + itemId"> Set Winner: </label>
                    <input
                      :id="'setWinner_' + itemId"
                      type="number"
                      class="form-control"
                      v-model="selected[index]"
                    />
                  </div>
                  <p v-if="getItemProperty(itemId, 'winner_number') !== null">
                    Juara: #{{ getItemProperty(itemId, "winner_number") }}
                  </p>
                </div>
              </li>
            </ul>
            <button class="btn btn-primary" type="submit">Submit</button>
          </b-card>
        </b-col>
      </b-row>
      <b-col cols="12" class="p-0 mt-2">
        <a
          @click="handleBackButton()"
          variant="secondary"
          class="btn waves-effect waves-float waves-light btn-secondary"
        >
          Back
        </a>
      </b-col>
    </form>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import vSelect from "vue-select";
import _ from "lodash";
import {
  BCard,
  BCardText,
  BForm,
  BButton,
  BCol,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import { successNotification, errorNotification } from "@/auth/utils";
export default {
  components: {
    BCard,
    BCardText,
    BForm,
    BButton,
    BCol,
    BRow,
    BSpinner,
    vSelect,
    InfiniteLoading,
  },

  data() {
    return {
      page: 1,
      winnerData: [],
      fixWinner: [],
      inputWinners: [],
      isLoading: false,
      stopLoading: false,
      filter: {
        search: "",
        order: "",
      },
      selectedItems: [],
      selected: [],
      result: {},
      orderList: [
        {
          name: "Most Liked",
          value: "most_liked",
        },
        {
          name: "Latest",
          value: "latest",
        },
      ],
      infiniteId: +new Date(),
    };
  },

  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
        this.page = 1;
        this.winnerData = [];
        this.infiniteId += 1;
      }, 300),
      deep: true,
    },
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  created() {
    this.getDataWinner();
  },

  // mounted() {
  //   this.infiniteHandler();
  // },

  methods: {
    handleBackButton() {
      history.back();
    },
    getItemProperty(itemId, property) {
      const item = this.winnerData.find((item) => item.uuid === itemId);
      if (item) {
        const properties = property.split(".");
        let value = item;
        for (let prop of properties) {
          if (value.hasOwnProperty(prop)) {
            value = value[prop];
          } else {
            value = "";
            break;
          }
        }
        return value;
      }
      return "";
    },
    isUrl(links) {
      const regex =
        /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^&\n?#]+)/;
      return regex.test(links);
    },
    extractVideoId(url) {
      const regex =
        /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^&\n?#]+)/;
      const match = url.match(regex);
      return match ? match[1] : "Invalid URL";
    },
    isImage(file) {
      const extension = file.split(".").pop().toLowerCase();
      return extension === "jpg" || extension === "jpeg" || extension === "png";
    },

    getData($state) {
      const params = this.$route.params.uuid;
      this.$http
        .get(`/api/v1/admin/competitions/${params}/uploads`, {
          params: {
            page: this.page,
            order: this.filter.order,
            search: this.filter.search,
          },
        })
        .then(({ data }) => {
          if (data.data.items.length) {
            this.page += 1;
            this.winnerData.push(...data.data.items);
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        });
    },

    changeType() {
      this.page = 1;
      this.winnerData = [];
      this.infiniteId += 1;
    },

    changeSearch() {
      this.page = 1;
      this.winnerData = [];
      this.infiniteId += 1;
    },
    // getData(page) {
    //   this.isLoading = true;
    //   const params = this.$route.params.uuid;
    //   this.currentPage = page;
    //   this.filter.page = page;
    //   this.$http
    //     .get(`/api/v1/admin/competitions/${params}/uploads`, {
    //       params: this.filter,
    //     })
    //     .then((response) => {
    //       this.result = response.data.data.meta;
    //       // this.winnerData.push(...response.data.data.items);
    //       this.winnerData = response.data.data.items;
    //       console.log(this.winnerData, "response winnerData");
    //       this.currentPage = response.data.data.meta.currentPage;
    //       this.isLoading = false;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    getDataWinner() {
      this.isLoading = true;
      const params = this.$route.params.uuid;
      this.$http
        .get(`/api/v1/admin/competitions/${params}/winners`)
        .then((response) => {
          this.fixWinner = response.data.data;
          this.selectedItems = this.fixWinner.map((e) => {
            return e.uuid;
          });
          // this.winnerItems = this.fixWinner.map((e) => {
          //   return {
          //     'uuid' : e.uuid,
          //     'winner_number' : e.winner_number
          //   };
          // });
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveWinners() {
      const params = this.$route.params.uuid;
      const payload = {};

      this.selectedItems.forEach((e, i) => {
        if (typeof this.selected[i] !== "undefined") {
          payload[`items[${i}][uuid]`] = e;
          payload[`items[${i}][winner_number]`] = this.selected[i];
        }
      });

      if (Object.keys(payload).length === 0) {
        return;
      }

      const encodedPayload = Object.keys(payload)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(payload[key])
        )
        .join("&");

      this.$http
        .post(
          `/api/v1/admin/competitions/${params}/set-winner`,
          encodedPayload,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          successNotification(this, "Success", "Pemenang Telah ditentukan!");
          this.selected = [];
          this.getDataWinner();
          this.$router.push({ name: "competition" });
        })
        .catch((error) => {});
    },

    deleteItem(slug) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete this Candidate",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$http
            .delete(`/api/v1/admin/competitions/uploads-delete/${slug}`)
            .then((response) => {
              this.getData(this.currentPage);
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Candidate successfully deleted",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
ul {
  padding: 0;
  list-style-type: unset;
}
.media_size {
  width: 100%;
  max-height: 292px;
  object-fit: cover;
}
.media-list-size {
  width: 100px;
  height: 100px;
  min-width: 100px;
  object-fit: cover;
}
.sticky-top {
  top: 100px;
}
.checkbox-position {
  position: absolute;
  right: 0;
}

.text-elipsis {
  display: inline-block;
  width: 100%;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
